import "bsky-embed/dist/bsky-embed.es.js"

const Bluesky = () => {

  return (
    <div className="pt-10 border-t mt-8 w-full px-8">
      <div className="rounded-xl border overflow-hidden pb-2">
        <div className="border-b px-4 py-3 font-semibold text-lg sm:text-xl text-center lg:text-lg lg:text-left xl:text-xl">
          Posts by <a href="https://bsky.app/profile/sidb-edinburgh.bsky.social">@sidb-edinburgh</a>
        </div>
        <bsky-embed
          username="sidb-edinburgh.bsky.social"
          limit="5"
          disable-styles="false"
          custom-styles="img.rounded-full { display: none; } .flex > div { max-width: 100%; } .whitespace-pre-wrap { word-wrap: break-word;} a { white-space: nowrap; }"
        >
        </bsky-embed>

      </div>
    </div>
  );
};

Bluesky.propTypes = {
  
};

export default Bluesky;