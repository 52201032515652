import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as InstagramIcon } from "../images/instagram-basic.svg";
import { ReactComponent as LinkedinIcon } from "../images/linkedin-basic.svg";
import { ReactComponent as BlueskyIcon } from "../images/bluesky-basic.svg";
import Sponsors from "./Sponsors";

const Footer = () => {

  const linkClass = "relative w-8 block"
  const iconClass = "absolute left-0 top-0 w-full h-full transition duration-300";
  const blueSkyClass = `${iconClass} p-1.5 border rounded-full border-[#666666] border-[1.5px] hover:border-[#f68d3d]`;

  return (
    <div className="bg-gray-100 pt-10 pb-20 px-6">
      <Sponsors />
      <div className="flex flex-col justify-between text-center gap-y-6 items-center mb-8 w-full max-w-4xl mx-auto sm:flex-row">
        <div className="text-sm text-gray-500 lg:text-base">
          Hugh Robson Building, George Sq., Edinburgh, EH8 9XF, United Kingdom
        </div>
        <div className="flex h-8 gap-2">
          <a href="https://www.instagram.com/sidb_edinburgh" className={linkClass}>
            <InstagramIcon className={iconClass} fill="#f68d3d" />
            <InstagramIcon className={`${iconClass} hover:opacity-0`} fill="#666666" />
          </a>
          <a href="https://www.linkedin.com/company/simons-initiative-for-the-developing-brain/" className={linkClass}>
            <LinkedinIcon className={iconClass} fill="#f68d3d" />
            <LinkedinIcon className={`${iconClass} hover:opacity-0`} fill="#666666" />
          </a>
          <a href="https://bsky.app/profile/sidb-edinburgh.bsky.social" className={linkClass}>
            <BlueskyIcon className={`${blueSkyClass} border-[#f68d3d]`} fill="#f68d3d" />
            <BlueskyIcon className={`${blueSkyClass} hover:opacity-0`} fill="#666666" />
          </a>
        </div>
      </div>
      <div className="w-full text-center border-t border-gray-300 max-w-4xl mx-auto text-gray-600 text-sm pt-7 mb-8">
        Copyright © {new Date().getFullYear()} Simons Initiative for the Developing Brain
      </div>
      <div className="w-full max-w-4xl mx-auto text-gray-700 flex flex-col items-center justify-center gap-2 pb-8 md:flex-row sm:gap-4 md:gap-6">
        <Link to="/contact/">Contact</Link>
        <a href="https://www.ed.ac.uk/about/website/privacy">Privacy & Cookies</a>
        <a href="https://www.ed.ac.uk/about/website/website-terms-conditions">Terms & Conditions</a>
        <a href="https://www.ed.ac.uk/about/website/accessibility">Accessibility</a>
        <a href="https://www.ed.ac.uk/about/website/freedom-information">Freedom of Information</a>
      </div>
      <div className="w-24 mx-auto border-b border-gray-300" />
    </div>
  );
};

Footer.propTypes = {
  
};

export default Footer;